<template>
  <no-ssr>
    <div class="bg-cercles">
      <div class="bb-login">
        <div class="bb-container">
          <div class="bb-login__container">
            <div class="login-box">
              <h1 class="login-box__title">{{ $t("login.accessToYourList", lang) }}</h1>

              <Form class="form" :operation="operation" :valid="valid" :error="error">
                <template>
                  <div class="login-box__inputs">
                    <InputText
                      :errorLabel="emailError"
                      :label="$t('login.yourEmail', lang) + ':'"
                      :placeholder="$t('login.yourEmail', lang)"
                      @focus="emailError = ''"
                      id="email"
                      v-model="email"
                    />
                    <InputText
                      :errorLabel="passwordError"
                      :label="$t('login.password', lang)"
                      :placeholder="$t('login.password', lang)"
                      @focus="passwordError = ''"
                      id="password"
                      type="password"
                      v-model="password"
                    />
                  </div>
                </template>

                <template slot="actions">
                  <div class="login-box__actions">
                    <input
                      type="submit"
                      :disabled="!valid"
                      :value="$t('login.logIn', lang)"
                      data-wait="Un segundo..."
                      class="button button--primary button--block button--lg"
                    />
                  </div>

                  <div class="login-box__recovery">
                    {{ $t("login.forgotPassword", lang) }}
                    <router-link to="/recoverpwd"> {{ $t("login.forgotPasswordCTA", lang) }} </router-link>
                  </div>

                  <login-social
                    :show="true"
                    :showFacebook="false"
                    :showLabel="true"
                    class="login-box__social"
                    color="white"
                    size="large"
                    :origin="origin"
                    :forcedLang="lang"
                    :verticalAlign="true"
                  ></login-social>
                </template>
              </Form>
            </div>

            <div class="login-account">
              <div class="login-account__picture">
                <img src="../../assets/img/logos/favicon.svg" alt="" />
              </div>
              <div class="login-account__content">
                <span>{{ $t("login.notYet", lang) }}</span>
                <router-link :to="signupUrl">{{ $t("login.getItNow", lang) }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </no-ssr>
</template>

<script>
import loginMixin from "../mixins/login.js";
import NoSSR from "vue-no-ssr";
import InputText from "../../components/forms/InputText.vue";
import LoginSocial from "../../components/ui/login/LoginSocial.vue";

export default {
  name: "LoginPage",
  mixins: [loginMixin],
  components: {
    InputText,
    "no-ssr": NoSSR,
    LoginSocial,
  },
  metaInfo() {
    return {
      title: this.$t("login.metaTitle", this.lang) + " | HelloBB",
      meta: [
        {
          name: "description",
          content:
            this.$t("login.metaDescription", this.lang),
        },
      ],
      link: [
        { rel: "canonical", href: "https://www.hellobb.net/login" },
        { rel: "alternate", href: "https://www.hellobb.net/connexion", hreflang: "fr" },
        { rel: "alternate", href: "https://www.hellobb.net/login", hreflang: "es-es" },
        { rel: "alternate", href: "https://www.hellobb.net/login", hreflang: "es-mx" },
        { rel: "alternate", href: "https://www.hellobb.net/login", hreflang: "es-co" },
        { rel: "alternate", href: "https://www.hellobb.net/login", hreflang: "es-ar" },
        { rel: "alternate", href: "https://www.hellobb.net/login", hreflang: "es-cl" },
        { rel: "alternate", href: "https://www.hellobb.net/login", hreflang: "es-uy" },
        { rel: "alternate", href: "https://www.hellobb.net/login", hreflang: "es-pe" },
      ],
      __dangerouslyDisableSanitizers: ['title', 'meta'],
    };
  },
  computed: {
    lang() {
      return this.$route.meta?.lang || this.$i18n.locale;
    },
    origin() {
      const origin = this.$route.query?.origin || "";

      const VALID_ORIGIN = ["signup", "extension"];
      if (origin && VALID_ORIGIN.indexOf(origin) >= 0) {
        return origin;
      }

      return "signup";
    },
    signupUrl() {
      let url = this.$t('routes.signup', this.lang);

      if (this.origin !== "signup") {
        url += `?origin=${encodeURIComponent(this.origin)}`
      }

      return url;
    },
  },
  data() {
    return {
      password: "",
      passwordError: "",
      email: "",
      emailError: "",
      loading: false,
      accessToken: "",
      userID: "",
      valid: true,
      msg: "",
      error: null,
    };
  },
  methods: {
    async operation() {
      if (this.validations()) {
        //this.executeScriptInHead();
        const response = await this.login("mi-lista");
        const statusCode = parseInt(response);

        if (statusCode >= 400 || statusCode < 500) {
          this.error = this.$t("login.wrongData", this.lang);
        } else {
          this.error = this.$t("login.error", this.lang);
        }
      }
    },
    validations() {
      if (!this.email) {
        this.emailError = this.$t("generic.field_is_required", this.lang);
      } else if (!this.$utils.validateEmail(this.email)) {
        this.emailError = this.$t("login.invalidEmailFormat", this.lang);
      }

      if (!this.password) {
        this.passwordError = this.$t("generic.field_is_required", this.lang);
      }

      return (
        !this.emailError &&
        !this.passwordError
      );
    },
    executeScriptInHead() {
      //if (cookieconsent.cookieConsentObject.userConsent.acceptedLevels.tracking) return;
      var scr_mixpanel = document.getElementById("scr_mixpanel");
      if (scr_mixpanel) {
        //scr_mixpanel.removeAttribute("data-cookie-consent");
        //scr_mixpanel.removeAttribute("cookie-consent");
        //execute script scr_mixpanel
        eval(scr_mixpanel.textContent);
      }
    },
  },
};
</script>
